<template>
  <b-card title="Số dư">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="mb-1 mb-sm-0">
        <span class="text-muted">
          <b-form-select
            v-model="stat"
            @change="changeStat"
          >
            <b-form-select-option value="today">Hôm nay</b-form-select-option>
            <b-form-select-option value="week">Tuần này</b-form-select-option>
            <b-form-select-option value="month">Tháng Này</b-form-select-option>
            <b-form-select-option value="yesterday">Hôm qua</b-form-select-option>
            <b-form-select-option value="last_week">Tuần trước</b-form-select-option>
            <b-form-select-option value="last_month">Tháng trước</b-form-select-option>
          </b-form-select>
        </span>
      </div>
      <div class="d-flex align-content-center mb-1 mb-sm-0">
        <h1 class="font-weight-bolder ">
          {{ balance }} {{ unit_money }}
        </h1>
        <div class="pt-25 ml-75">
          <b-button
            variant="success"
            size="sm"
            @click="refresh"
          >
            <feather-icon
              v-if="!loading"
              icon="RefreshCcwIcon"
              size="12"
              class="text-danger align-middle"
            />
            <b-spinner
              v-if="loading"
              small
            /> Tải lại</b-button>
        </div>
      </div>
    </div>
    <!-- echart -->
    <b-overlay :show="loading">
      <app-echart-line
        v-if="option"
        :option-data="option"
      />
    </b-overlay>

  </b-card>
</template>

<script>
import { BOverlay, BCard, BFormSelect, BFormSelectOption, BButton, BSpinner } from 'bootstrap-vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BOverlay,
    BSpinner,
    BButton,
    BFormSelectOption,
    BFormSelect,
    BCard,
    AppEchartLine,
  },
  data() {
    return {
      loading: false,
      balance: 0,
      unit_money: 'vnd',
      stat: 'today',
      option: {
        xAxisData: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00',
          '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '18:00', '19:00',
          '20:00', '21:00', '22:00', '23:00'],
        series: [],
      },
    }
  },
  created() {
    // default today
    this.changeStat('today')
  },
  methods: {
    refresh() {
      this.changeStat(this.stat)
    },
    // select
    changeStat(e) {
      this.stat = e
      this.loading = true
      useJwt.getStatictisc(e)
        .then(({ data }) => {
          this.loading = false
          this.option = data.data
          this.balance = new Intl.NumberFormat().format(data.data.balance)
        })
        .catch(e => {
          if (e.response.status === 404) {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: '404 not found!',
                icon: 'CoffeeIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
  },
}
</script>
